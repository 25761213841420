import { lazy, Suspense } from "react";
import HomeSlider from "modules/Home/components/HomeSlider";
import HomeAbout from "modules/Home/components/HomeAbout";
import ProductLines from "modules/Home/components/ProductLines";
import HomeFeaturedProject from "modules/Home/components/HomeFeaturedProject";
import ScrollTopOnMount from "utils/components/ScrollTopOnMount";
import HomePostsList from "modules/Home/components/HomePostsList";
import HomeWhereToBuy from "modules/Home/components/HomeWhereToBuy";
import SEO from "services/seo/components/SEO";
import { HOME_SCENE } from "services/seo/constants/scenes";
import { isLATAM, isMARINE, isRESOURCES } from "services/instances";
import ServicesLines from "modules/Home/components/ServicesLines";

const StoreFinderContainer = lazy(() =>
  import("modules/stores/WhereToBuyPage/components/StoreFinder/container")
);

export default function Home() {
  const hideWhereToBuySection = isMARINE() || isRESOURCES() || isLATAM();

  return (
    <div className="home">
      <ScrollTopOnMount />
      <HomeSlider />
      {!isRESOURCES() ? (
        <>
          <HomeAbout />
          <ProductLines />
        </>
      ) : (
        <ServicesLines />
      )}
      <HomePostsList />
      <HomeFeaturedProject />
      {!hideWhereToBuySection && (
        <>
          <HomeWhereToBuy />
          <Suspense fallback={<div />}>
            <StoreFinderContainer />
          </Suspense>
        </>
      )}
      <SEO scene={HOME_SCENE} />
    </div>
  );
}
